<template>
    <div>
        <TheBasePageLayout fullSidebar pageHeading="Lookup Tool">
            <NotificationBar :notifications="notifications" />

            <!-- Sidebar -->
            <div slot="sidebar">
                <v-form id="isbn-search" @submit.prevent="searchHandler(queryString)">
                    <v-textarea
                        id="isbn-input-search"
                        v-model.trim="queryString"
                        type="text"
                        dense
                        hideDetails
                        backgroundColor="white"
                        placeholder="Enter ISBN(s) separated by commas, spaces, or newline"
                        class="textarea"
                        appendOuterIcon="mdi-magnify"
                    />

                    <v-btn
                        type="submit"
                    >
                        Submit
                    </v-btn>

                </v-form>

                <v-divider class="my-4" color="white" />

                <div class="px-4">
                    <v-btn
                        v-if="!loading && results.length != 0"
                        class="mt-8 mb-4"
                        block
                        @click="csvDownload()"
                    >
                        <v-icon dark>mdi-download</v-icon>
                        &nbsp;Download
                    </v-btn>

                    <v-btn
                        v-if="!loading && results.length != 0"
                        block
                        @click="copyToClipboard()"
                    >
                        <v-icon dark>mdi-content-copy</v-icon>
                        &nbsp;Copy Results
                    </v-btn>
                </div>
            </div>

            <!-- Main -->
            <div slot="main">

                <h2>Failed Results ({{ results.filter( ( result ) => result.status === 'Failed').length }}/{{ results.length }})</h2>
                <v-data-table
                    id="failed-table"
                    :headers="failedHeaders"
                    :items="results.filter( ( result ) => result.status === 'Failed' )"
                    :loading="loading"
                    :itemsPerPage="10"
                    :footerProps="{ itemsPerPageOptions: [ 10, 25, 50 ] }"
                >

                    <template #[`item.listPrice`]="{item}" >
                        ${{ FormatUtil.toCurrency( item.listPrice ) }}
                    </template>

                    <template #[`item.status`]="{item}" >
                        <v-chip
                            :color="item.status === 'Success' ? 'success' : 'error'"
                            textColor="white"
                            size="small"
                        >
                            {{ item.status }}
                        </v-chip>
                    </template>
                </v-data-table>

                <h2 class="mt-8">Successful Results ({{ results.filter( ( result ) => result.status === 'Success' ).length }}/{{ results.length }})</h2>
                <v-data-table
                    id="success-table"
                    :headers="successHeaders"
                    :items="results.filter( ( result ) => result.status === 'Success' )"
                    :loading="loading"
                    :itemsPerPage="10"
                    :footerProps="{ itemsPerPageOptions: [ 10, 25, 50 ] }"
                    class="mb-10"
                >

                    <template #[`item.listPrice`]="{item}" >
                        ${{ FormatUtil.toCurrency( item.listPrice ) }}
                    </template>

                    <template #[`item.publishDate`]="{item}" >
                        {{ formatPublishDate( item.publishDate ) }}
                    </template>

                    <template #[`item.amazonNewLow`]="{item}" >
                        {{ formatCurrency( item.amazonLookupNewLow ) }}
                    </template>

                    <template #[`item.amazonUsedLow`]="{item}" >
                        {{ formatCurrency( item.amazonLookupUsedLow ) }}
                    </template>

                    <template #[`item.status`]="{item}" >
                        <v-chip
                            :color="item.status === 'Success' ? 'success' : 'error'"
                            textColor="white"
                            size="small"
                        >
                            {{ item.status }}
                        </v-chip>
                    </template>
                </v-data-table>
            </div>
        </TheBasePageLayout>
    </div>
</template>

<script>
import FormatUtil from '@/utils/FormatUtil';

// Components
import TheBasePageLayout from '@/components/base/TheBasePageLayout';
import { mapState, mapActions } from 'vuex';
import NotificationBar from '@/components/NotificationBar';

export default {
    components: {
        NotificationBar,
        TheBasePageLayout,
    },
    data() {
        return {
            FormatUtil,
            queryString: '',
            failedHeaders: [
                {
                    text: 'ISBN',
                    value: 'isbn',
                    width: '200px',
                },
                {
                    text: 'Error Message',
                    value: 'statusDescription',
                },
                {
                    text: 'Status',
                    value: 'status',
                    align: 'center',
                    width: '150px',
                },
            ],
            successHeaders: [
                {
                    text: 'ISBN',
                    value: 'isbn',
                    width: '200px',
                },
                {
                    text: 'ASIN',
                    value: 'asin',
                },
                {
                    text: 'List Price',
                    value: 'listPrice',
                },
                {
                    text: 'Sales Rank',
                    value: 'salesRank',
                },
                {
                    text: 'Publication Date',
                    value: 'publishDate',
                },
                {
                    text: 'Lowest New Price',
                    value: 'amazonNewLow',
                },
                {
                    text: 'Lowest Used Price',
                    value: 'amazonUsedLow',
                },
                {
                    text: 'Status',
                    value: 'status',
                    align: 'center',
                    width: '150px',
                },
            ],
        };
    },
    computed: {
        ...mapState( {
            results: ( state ) => state.Lookup.results,
            loading: ( state ) => state.Lookup.loading,
            notifications: ( state ) => state.notifications,
        } ),
        dateTime() {
            const dateArray = new Date()
                .toISOString()
                .slice( 0, 16 )
                .replace( /T/g, '_' )
                .split( '_' );
            const timeArray = dateArray[ 1 ].split( ':' );
            const hour = timeArray[ 0 ] > 12 ? timeArray[ 0 ] - 12 : timeArray[ 0 ];
            return `${ dateArray[ 0 ] }_${ hour }-${ timeArray[ 1 ] }`;
        },
    },
    methods: {
        formatPublishDate( date ) {
            if ( !date ) {
                return '';
            }

            return FormatUtil.epochToUTCString( date, { format: 'YYYY-MM-DD' } );
        },

        formatCurrency( price ) {
            if ( price === null ) {
                return '';
            }

            return `$${ FormatUtil.toCurrency( price ) }`;
        },

        prepareCSV() {
            const headers = 'ISBN,ASIN,List Price,Sales Rank,Publication Date,Lowest New Price,Lowest Used Price,Status,Status Description';
            let table = headers;

            this.results.forEach( ( result ) => {
                table += '\n';
                table += Object.values( {
                    isbn: result.isbn,
                    asin: result.asin,
                    listPrice: `$${ FormatUtil.toCurrency( result.listPrice ) }`,
                    salesRank: result.salesRank,
                    publishDate: this.formatPublishDate( result.publishDate ),
                    amazonLookupNewLow: `$${ FormatUtil.toCurrency( result.amazonLookupNewLow ) }`,
                    amazonLookupUsedLow: `$${ FormatUtil.toCurrency( result.amazonLookupUsedLow ) }`,
                    status: result.status,
                    statusDescription: result.statusDescription,
                } );
            } );

            return table;
        },

        csvDownload() {
            const table = this.prepareCSV();
            const url = window.URL.createObjectURL( new Blob( [ table ] ) );
            const link = document.createElement( 'a' );

            link.href = url;
            link.setAttribute( 'download', `lookupResults_${ this.dateTime }.csv` );
            document.body.appendChild( link );
            link.click();
        },

        copyToClipboard() {
            const table = this.prepareCSV();
            const el = document.createElement( 'textarea' );
            el.value = table;
            document.body.appendChild( el );
            el.select();
            document.execCommand( 'copy' );
            document.body.removeChild( el );
            this.success( 'Copied to clipboard' );
        },
        ...mapActions( {
            searchHandler: 'Lookup/searchHandler',
            success: 'setSuccessNotification',
        } ),
    },
};
</script>

<style lang="scss">
#isbn-search {
    padding: 2rem 1rem;

    .v-textarea {
        border-radius: 5px !important;

        .v-icon {
            color: #FFFFFF;
        }
    }

    .v-btn {
        width: 50%;
        margin-top: 1.5rem;
    }
}
#table .v-data-footer {
    position: relative;
    z-index: 3;
}
</style>
