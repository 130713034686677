<template>
    <div>
        <TheBasePageLayout
            fullSidebar
            pageHeading="Title Grouping"
        >
            <!-- Sidebar -->
            <div slot="sidebar">
                <SidebarTitleGroupSearch v-bind.sync="titleGroup"/>
            </div>

            <!-- Main -->
            <div slot="main">
                <TableBaseFilterable
                    :headers="titleGroup.headers"
                    :items="titleGroupList"
                    :itemClass="setRowHighlight"
                    v-bind.sync="titleGroup"
                    cardElevation="3"
                    showSelect
                    hideAdd
                    itemKey="titleId"
                >
                    <template #[`item.hasDigitalPricing`]="{item}">
                        {{ item.hasDigitalPricing ? 'Yes' : 'No' }}
                    </template>
                </TableBaseFilterable>
            </div>
        </TheBasePageLayout>
        <ClModal :showModal="showCreateGroupModal" width="400px">
            <v-card>
                <v-card-title>Create Group for Title</v-card-title>
                <v-card-text>Would you like to run the automatic Title Grouping process on this ISBN to search for digital availability?</v-card-text>
                <v-card-text
                    v-if="showErrorMessage"
                    class="error--text">
                    No digital availability found for this ISBN. This ISBN will remain without a Title Group
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <ClButton type="secondary" :loading="inFlight" :disabled="inFlight" @click="handleModalCancel">{{showErrorMessage ? 'OK' :'No'}}</ClButton>
                    <ClButton v-if="!showErrorMessage" :loading="inFlight" :disabled="inFlight" type="primary" @click="handleModalConfirm">Yes</ClButton>
                </v-card-actions>
            </v-card>
        </ClModal>
    </div>

</template>

<script>
// Vuex
import { mapGetters, mapState } from 'vuex';

// Components
import TheBasePageLayout from '@/components/base/TheBasePageLayout';
import TableBaseFilterable from '@/components/base/table/TableBaseFilterable';
import SidebarTitleGroupSearch from './titleGroupSearch/SidebarTitleGroupSearch';

export default {
    name: 'TitleGroupSearch',
    components: {
        TheBasePageLayout,
        SidebarTitleGroupSearch,
        TableBaseFilterable,
    },
    props: {
        query: {
            type: String,
            required: false,
            default: '',
        },
    },
    data() {
        return {
            titleGroup: {
                selected: [],
                filter: '',
                headers: [
                    {
                        text: 'EAN',
                        value: 'productCode',
                    },
                    {
                        text: 'Title',
                        value: 'descriptionFriendly',
                    },
                    {
                        text: 'Publisher',
                        value: 'publisher',
                    },
                    {
                        text: 'Author',
                        value: 'author',
                    },
                    {
                        text: 'Edition',
                        value: 'edition',
                    },
                    {
                        text: 'Digital Pricing',
                        value: 'hasDigitalPricing',
                    },
                ],
            },
            showErrorMessage: false,
        };
    },
    computed: {
        ...mapGetters( {
            titleGroupList: 'TitleGroup/resultList',
            titleGroupListCount: 'TitleGroup/resultCount',
        } ),
        ...mapState( {
            inFlight: ( state ) => state.TitleGroup.results.metadata.inFlight,
            showCreateGroupModal: ( state ) => state.TitleGroup.showCreateGroupModal,
        } ),
    },
    watch: {
        query: {
            handler: 'propSearchHandler',
            immediate: true,
        },
    },
    methods: {
        async propSearchHandler() {
            if ( this.query ) {
                // Set the string in the 'search' input so the user isn't confused as to what we're searching
                this.$store.commit( 'TitleGroup/SET_SEARCH_STRING', { string: this.query } );
                this.$store.dispatch( 'TitleGroup/getListByIsbn', { isbn: this.query } );
            }
        },
        /** @returns { String } */
        setRowHighlight( row ) {
            return this.$route.query
                && this.$route.query.q
                && this.$route.query.q === row.productCode
                ? 'is-searched-title'
                : '';
        },
        handleModalCancel() {
            this.$store.commit( 'TitleGroup/SET_SHOW_CREATE_GROUP_MODAL', { show: false } );
            this.showErrorMessage = false;
            this.$router.push( '/group' );
        },
        async handleModalConfirm() {
            const titleId = this.$route.query.q;
            const result = await this.$store.dispatch( 'TitleGroup/createGroup', { titleId } );
            if ( !result ) {
                this.showErrorMessage = true;
            } else {
                this.$store.commit( 'TitleGroup/SET_SHOW_CREATE_GROUP_MODAL', { show: false } );
                this.$router.go(); // With No params this reloads the page
            }
        },
    },
};
</script>
<style scoped>
::v-deep .is-searched-title td:first-child {
    border-left: 4px solid var(--v-primary-base);
}
</style>
