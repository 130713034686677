<template>
    <div class="sidebar-container">

        <!-- Search by EAN -->
        <div class="sidebar-container__section">
            <h3 class="sidebar-container__header">EAN</h3>
            <InputSearch
                class="input"
                :value.sync="queryString"
                placeholder="EAN"
                @search="searchHandler()"
            />
        </div>

        <!-- Action Pane -->
        <div
            v-if="currentTitleGroupId"
            class="sidebar-container__section action-container"
        >
            <div class="action-container__header">
                <h3>Group Actions</h3>
                <h3>
                    Current Title Group Id: {{ currentTitleGroupId }}
                </h3>
            </div>
            <!-- Add EAN to Current Group -->
            <div class="action-container__item">
                <div class="action-container__item__heading">
                    <h3>Add</h3>
                    <span>EAN to Current Group</span>
                </div>
                <div class="action-container__item__input-row">
                    <v-text-field
                        v-model.trim="isbnToBeAdded"
                        dense
                        hideDetails
                        outlined
                        @keydown.enter="addIsbnHandler()"
                    />
                </div>
                <div class="action-container__item__button-row">
                    <v-btn
                        class="action-container__item__button-row__button"
                        color="primary"
                        @click="addIsbnHandler()"
                    >
                        Add EAN
                    </v-btn>
                </div>
            </div>

            <!-- Remove Selected Titles from the Current Group -->
            <div class="action-container__item">
                <div class="action-container__item__heading">
                    <h3>Remove</h3>
                    <span>Selected Titles</span>
                </div>
                <div class="action-container__item__button-row">
                    <v-btn
                        :disabled="!hasCheckedItems"
                        color="warning"
                        @click="removeHandler()"
                    >
                        Remove Selected Titles
                    </v-btn>
                </div>
            </div>

            <!-- Dissolve the Current Group -->
            <div class="action-container__item">
                <div class="action-container__item__heading">
                    <h3>Dissolve</h3>
                    <span>Group</span>
                </div>
                <div class="action-container__item__button-row">
                    <v-btn
                        color="warning"
                        @click="dissolveGroupHandler()"
                    >
                        Dissolve Group
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// Vuex
import { mapGetters, mapState } from 'vuex';

// Components
import InputSearch from '@/components/InputSearch';

export default {
    name: 'SidebarTitleGroupSearch',
    components: {
        InputSearch,
    },
    props: {
        selected: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            isbnToBeAdded: '',
            isMergeAvailable: false,
        };
    },
    computed: {
        ...mapGetters( {
            authToken: 'User/authString',
        } ),
        ...mapState( {
            inFlight: ( state ) => state.TitleGroup.results.metadata.inFlight,
            currentTitleGroupId: ( state ) => state.TitleGroup.results.groupId,
            checkedItems: ( state ) => state.TitleGroup.checkedItems,
            searchString: ( state ) => state.TitleGroup.searchString,
        } ),
        /** @returns { Boolean } */
        hasCheckedItems() {
            return this.selectedList.length > 0;
        },
        /** @returns { String } */
        queryString: {
            get() {
                return this.searchString;
            },
            set( string ) {
                this.$store.commit( 'TitleGroup/SET_SEARCH_STRING', { string } );
            },
        },
        selectedList: {
            get() {
                return this.selected;
            },
            set( val ) {
                this.$emit( 'update:selected', val );
            },
        },
    },
    methods: {
        async searchHandler() {
            if ( this.queryString ) {
                this.$router.push( {
                    name: 'TitleGroupSearch',
                    query: { q: this.queryString.trim() },
                } );
            }
        },

        async addIsbnHandler() {
            try {
                await this.$store.dispatch( 'TitleGroup/addTitleToGroupById', {
                    isbn: this.isbnToBeAdded,
                    titleGroupId: this.currentTitleGroupId,
                } );
                // Clear the isbn input field upon success
                this.isbnToBeAdded = '';
            } catch ( error ) {
                console.log( 'Error in addIsbnHandler: ', error );
            }
        },

        // ??? handle that merge
        mergeHandler() {
            console.log( 'merge coming soon' );
        },

        async removeHandler() {
            const titleIdList = this.selected.map( ( item ) => item.titleId );
            this.$store.dispatch( 'TitleGroup/removeTitleListFromGroupById', { titleIdList } );
            this.selectedList = [];
        },

        async dissolveGroupHandler() {
            // Bail out if they haven't made peace
            // eslint-disable-next-line no-restricted-globals,no-alert
            if ( !confirm( 'Are you sure you want to dissolve this title group?' ) ) {
                return;
            }

            this.$store.dispatch( 'TitleGroup/dissolveGroupByGroupId' );
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";

.sidebar-element-container {
    border-radius: 5px;
    padding: 0.5rem;
}

.action-container {
    margin-top: 1rem;
    padding: 1rem;
    color: black;
    box-shadow: $material-shadow-1;
    &__header {
        color: white;
        text-align: center;
        margin-bottom: 1rem;
    }

    &__item {
        background-color: white;
        box-shadow: $material-shadow-1;

        margin-top: 0.5rem;
        padding: 0.5rem;
        border-radius: 5px;

        &__heading {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
        }

        &__input-row {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
        &__button-row {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;

            &__icon {
                margin-right: 0.25rem;
            }
        }
    }
}
</style>
