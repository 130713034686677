<template>
    <div class="input-value-search">
        <v-tooltip top :disabled="!tooltip">
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    id="input-search"
                    v-model="searchText"
                    type="text"
                    dense
                    hideDetails
                    backgroundColor="white"
                    :placeholder="placeholder"
                    v-bind="attrs"
                    v-on="on"
                    @input="$emit( 'update:value', $event )"
                />
            </template>
            <span v-if="tooltip">{{ tooltip }}</span>
        </v-tooltip>
            <!-- @keydown.enter="$emit( 'search', value )" -->

        <span>
            <v-icon
                class="material-icons"
                dark
                @click="$emit( 'search', value )"
            >
                mdi-magnify
            </v-icon>
        </span>
    </div>
</template>

<script>
/**
 * @emits 'update:value' => { String }
 * @emits 'search' => { String }
 */
export default {
    name: 'InputSearch',
    props: {
        tooltip: {
            type: String,
            required: false,
            default: '',
        },
        placeholder: {
            type: String,
            required: false,
            default: '',
        },
        value: {
            type: [ String, Number ],
            required: false,
            default: '',
        },
    },
    computed: {
        searchText: {
            get() {
                return this.value;
            },
            set( val ) {
                this.$emit( 'input', val );
            },
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";
@import "@/assets/sass/sidebar.scss";

.input-value-search {
    margin-bottom: 0;
    padding-bottom: 0;
    position: relative;
    font-size: 16px;
    display: flex;

    .v-input {
        width: 80%;
        border-radius: 4px;
    }

    .material-icons {
        padding:1px;
        margin: 4px;
        cursor: pointer;
    }
}

</style>
